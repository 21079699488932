import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loading from '../components/Loading';

const Login = lazy(() => import('../views/Login'));
const Home = lazy(() => import('../views/Home'));
const Revivelo = lazy(() => import('../views/Revivelo'));
const Agenda = lazy(() => import('../views/Agenda'));
const Comunidad = lazy(() => import('../views/Comunidad'));
const Streaming = lazy(() => import('../views/Streaming'));
const Pruebas = lazy(() => import('../views/pruebas-streaming'));
const SeccionVideos = lazy(() => import('../views/Seccionvideos'));
const Videos = lazy(() => import('../views/Videos'));
const Plataformas = lazy(() => import('../views/Plataformas'));
const VistaRA = lazy(() => import('../views/VistaRA'));
const VerificarEmail = lazy(() => import('../views/VerificarEmail'));

// Admin
const AdminDashboard = lazy(() => import('../views/Admin/Dashboard'));
const AdminDev = lazy(() => import('../views/Admin/Dev'));
const AdminUsuarios = lazy(() => import('../views/Admin/Usuarios'));
const AdminCrear = lazy(() => import('../views/Admin/Usuarios/Crear'));

export default function App() {
    return (
        <BrowserRouter>
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route exact path="/" element={<Login />} />
                    <Route exact path="/home" element={<Home />} />
                    <Route exact path="/revivelo" element={<Revivelo />} />
                    <Route exact path="/agenda" element={<Agenda />} />
                    <Route exact path="/comunidad" element={<Comunidad />} />
                    <Route exact path="/streaming" element={<Streaming />} />
                    <Route exact path="/pruebas" element={<Pruebas />} />
                    <Route exact path="/vistaRA" element={<VistaRA />} />
                    <Route exact path="/seccion-videos" element={<SeccionVideos />} />
                    <Route exact path="/videos/:id" element={<Videos />} />
                    <Route exact path="/plataformas" element={<Plataformas />} />
                    <Route exact path="/verificar-email/:id/:timestamp" element={<VerificarEmail />} />
                    {/* Admin */}
                    <Route exact path="/admin/dashboard" element={<AdminDashboard />} />
                    <Route exact path="/admin/dev" element={<AdminDev />} />
                    <Route exact path="/admin/usuarios" element={<AdminUsuarios />} />
                    <Route exact path="/admin/usuarios/crear" element={<AdminCrear />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}